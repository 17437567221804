import React, { useEffect, useState } from "react";
import CustomerService from "../../services/customers/customers";

const AsrValues = ({
  setShowAsrValues,
  customerId,
  successValue,
  warningValue,
  setSuccessValue,
  setWarningValue,
}) => {
  const [actualSuccessValue, setActualSuccessValue] = useState("");
  const [actualWarningValue, setActualWarningValue] =
    useState("setWarningValue");
  const [response, setResponse] = useState({ status: "", message: "" });
  const [countdown, setCountdown] = useState(5);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = () => {
    CustomerService.updateAsrValues({
      customerId,
      asrValues: { green: actualSuccessValue, yellow: actualWarningValue },
    }).then((response) => {
      setResponse({
        status: "success",
        message: `ASR Values updated successfully`,
      });
      setSuccessValue(actualSuccessValue);
      setWarningValue(actualWarningValue);
      setIsLoading(false);
      const intervalId = setInterval(() => {
        setCountdown((currentCountdown) => {
          if (currentCountdown <= 1) {
            clearInterval(intervalId);

            setShowAsrValues(false);
            return 0;
          }
          return currentCountdown - 1;
        });
      }, 1000);
    });
  };

  useEffect(() => {
    setActualSuccessValue(successValue);
    setActualWarningValue(warningValue);
  }, [successValue, warningValue]);

  const handleValueChange = (value, type) => {
    if (value === "" || value.match(/^\d*\.?\d{0,2}$/)) {
      if (type === "success") {
        setActualSuccessValue(value);
        const newWarningValue = (parseFloat(value) - 20).toFixed(2);
        setActualWarningValue(newWarningValue > 0 ? newWarningValue : "0.00");
      } else if (type === "warning") {
        setActualWarningValue(value);
      }
    }
  };

  const formatValueOnBlur = (type) => {
    let value = type === "success" ? actualSuccessValue : actualWarningValue;
    let formattedValue = parseFloat(value).toFixed(2);
    if (!isNaN(formattedValue)) {
      if (type === "success") {
        setActualSuccessValue(formattedValue);
        const newWarningValue = (parseFloat(formattedValue) - 20).toFixed(2);
        setActualWarningValue(newWarningValue > 0 ? newWarningValue : "0.00");
      } else if (type === "warning") {
        if (parseFloat(formattedValue) >= parseFloat(actualSuccessValue)) {
          setActualWarningValue(
            (parseFloat(actualSuccessValue) - 1).toFixed(2)
          );
        } else {
          setActualWarningValue(formattedValue);
        }
      }
    } else {
      if (type === "success") {
        setActualSuccessValue("60.00");
        setActualWarningValue("40.00");
      } else if (type === "warning") {
        setActualWarningValue((parseFloat(actualSuccessValue) - 20).toFixed(2));
      }
    }
  };
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 max-w-lg w-full">
          <div className="gap-4 w-full">
            <div className="mb-4 w-full">
              <label className="text-2xl font-semibold dark:text-white flex items-center mb-6">
                ASR Values
              </label>
            </div>
            <div className="flex flex-col items-start gap-2">
              <div className="flex items-center">
                <span className="bg-[#3FCEA6] inline-flex items-center px-2 text-md rounded-full font-medium mr-1 mb-1 text-white">
                  SUCCESS
                </span>
                <input
                  type="text"
                  value={actualSuccessValue}
                  onChange={(e) => handleValueChange(e.target.value, "success")}
                  onBlur={() => formatValueOnBlur("success")}
                  className="input-style dark:text-black w-[70px] rounded-xl px-2"
                />

                <div className="text-sm dark:text-gray-400 ml-2">
                  From {actualSuccessValue}% of
                </div>
              </div>
              <div className="flex items-center">
                <span className="bg-[#FFD700] inline-flex items-center px-2 text-md rounded-full font-medium mr-1 mb-1 text-white">
                  WARNING
                </span>
                <input
                  type="text"
                  value={actualWarningValue}
                  onChange={(e) => handleValueChange(e.target.value, "warning")}
                  onBlur={() => formatValueOnBlur("warning")}
                  className="input-style dark:text-black w-[70px] rounded-xl px-2"
                />

                <div className="text-sm dark:text-gray-400 ml-2">
                  From {actualWarningValue}% to {actualSuccessValue}%{" "}
                </div>
              </div>
              <div className="flex items-center">
                <span className="bg-[#FF6347] inline-flex items-center px-2 text-md rounded-full font-medium mr-1 mb-1 text-white">
                  DANGER
                </span>
                <div className="text-sm dark:text-gray-400">
                  Below {actualWarningValue}%
                </div>
              </div>
            </div>
            <div className="font-semibold text-sm mt-[6vh]">
              {response && response.status && response.message ? (
                <>
                  {response.status === "error" ? (
                    <div className="text-red-500">{response.message}</div>
                  ) : (
                    <div className="text-green-600">
                      {response.status === "success" && countdown > 0
                        ? `ASR Values updated successfully: closing in ${countdown} second(s)...`
                        : response.message}
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
            <div className="flex justify-between mt-10">
              <button
                className="px-4 py-2 bg-gray-500 text-white text-sm rounded font-medium hover:bg-gray-600"
                onClick={() => setShowAsrValues(false)}
              >
                Close
              </button>
              <button
                className="px-4 py-2 bg-gray-500 text-white text-sm rounded font-medium hover:bg-gray-600"
                onClick={handleSave}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AsrValues;
