import React, { useState, useEffect, useMemo } from "react";
import { PieChart, Pie, Cell } from "recharts";
import StatsService from "../../../services/stats/stats";

const TableWithZoneData = ({
  customerId,
  startDate,
  endDate,
  filters,
  successValue,
  warningValue,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isErrored, setIsErrored] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  useEffect(() => {
    setIsErrored(false);
    setIsLoading(true);
    const data = {
      startDate,
      endDate,
      filters,
    };
    StatsService.dataByZone({ customerId, data })
      .then(({ data: { message: zones } }) => {
        setData(zones);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsErrored(true);
        setIsLoading(false);
      });
  }, [customerId, startDate, endDate, filters, successValue, warningValue]);

  const sortedAndFilteredData = useMemo(() => {
    let filteredData = data.filter((item) =>
      item.zone.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortConfig.key !== null && sortConfig.direction !== null) {
      filteredData.sort((a, b) => {
        let isAscending = sortConfig.direction === "ascending";
        if (
          sortConfig.key === "answered" ||
          sortConfig.key === "converted" ||
          sortConfig.key === "total"
        ) {
          let valueA = Number(a[sortConfig.key]);
          let valueB = Number(b[sortConfig.key]);
          return isAscending ? valueA - valueB : valueB - valueA;
        } else {
          if (
            a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()
          ) {
            return isAscending ? -1 : 1;
          }
          if (
            a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()
          ) {
            return isAscending ? 1 : -1;
          }
        }
        return 0;
      });
    }
    return filteredData;
  }, [data, searchTerm, sortConfig]);

  const getColor = (value) => {
    if (value) {
      if (value < parseInt(warningValue)) {
        return "#ef4444";
      } else if (
        value > parseInt(warningValue) &&
        value < parseInt(successValue)
      ) {
        return "#f59e0b";
      } else if (value >= parseInt(successValue)) {
        return "#40CEA6";
      }
    } else {
      return "white";
    }
  };
  useEffect(() => {
    setIsLoading(true);
    StatsService.dataByZone({ customerId })
      .then(({ data: { message: zones } }) => {
        setData(zones);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [customerId]);

  const onSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      direction = null;
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    return sortConfig.key === key
      ? sortConfig.direction === "ascending"
        ? "▲"
        : "▼"
      : "";
  };

  return (
    <div class="rounded-xl w-full p-4 dark:bg-[#1D1E24] bg-white dark:text-white text-gray-800 h-full overflow-y-scroll">
      {isLoading || isErrored ? (
        <div className="status h-full w-full items-center justify-center flex">
          {isErrored ? (
            <>
              <div className="text-white font-medium text-sm bg-red-500 rounded-full px-2">
                Error loading chart
              </div>
            </>
          ) : (
            <>
              {" "}
              <svg
                aria-hidden="true"
                class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="flex flex-col w-full">
            <div className="flex justify-between mb-4">
              <h3 className="text-sm font-semibold h-[5%] w-full">
                Data by Zone
              </h3>

              <input
                type="text"
                placeholder="Search by zone..."
                className="p-2 border rounded-xl h-6 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="hidden sm:block py-2 align-middle w-full">
              <table className="w-full">
                <thead>
                  <tr className="border-b dark:border-white">
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer"
                      onClick={() => onSort("zone")}
                    >
                      Zone {getSortIcon("zone")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer"
                      onClick={() => onSort("total")}
                    >
                      Total Calls {getSortIcon("total")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer"
                      onClick={() => onSort("answered")}
                    >
                      ASR {getSortIcon("answered")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer"
                      onClick={() => onSort("converted")}
                    >
                      CRP GEO {getSortIcon("converted")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedAndFilteredData.map((item, idx) => (
                    <tr key={idx} className="border-b dark:border-white">
                      <td className="px-6 whitespace-nowrap text-xs w-2/4">
                        {item.zone}
                      </td>
                      <td className="px-6 whitespace-nowrap text-xs w-2/4">
                        {item.total}
                      </td>
                      <td className="text-xs w-1/4">
                        <div className="flex items-center">
                          <PieChart width={60} height={60}>
                            <Pie
                              data={[
                                { name: "Filled", value: item.answered },
                                { name: "Empty", value: 100 - item.answered },
                              ]}
                              cx={25}
                              cy={25}
                              innerRadius={8}
                              outerRadius={16}
                              fill={getColor(item.answered)}
                              dataKey="value"
                              startAngle={90}
                              endAngle={-270}
                            >
                              <Cell
                                key={`cell-filled`}
                                fill={getColor(item.answered)}
                              />
                              <Cell key={`cell-empty`} fill="#ddd" />
                            </Pie>
                          </PieChart>
                          {item.answered}%
                        </div>
                      </td>
                      <td className="text-xs w-1/4">
                        <div className="flex items-center">
                          <PieChart width={60} height={60}>
                            <Pie
                              data={[
                                { name: "Filled", value: item.converted },
                                {
                                  name: "Empty",
                                  value: 100 - item.converted,
                                },
                              ]}
                              cx={25}
                              cy={25}
                              innerRadius={8}
                              outerRadius={16}
                              fill={getColor(item.converted)}
                              dataKey="value"
                              startAngle={90}
                              endAngle={-270}
                            >
                              <Cell
                                key={`cell-filled`}
                                fill={getColor(item.converted)}
                              />
                              <Cell key={`cell-empty`} fill="#ddd" />
                            </Pie>
                          </PieChart>
                          {item.converted}%
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div class="sm:hidden overflow-y-scroll">
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan="100%">
                    <div className="loading-bar"></div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {sortedAndFilteredData.length === 0 ? (
                  <tr className="">
                    <td colSpan="9" className="text-center py-2">
                      <div className="flex justify-center  items-center w-full dark:text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-mood-empty-filled mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path
                            d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 10.66h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-5.99 -5l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                            stroke-width="0"
                            fill="currentColor"
                          ></path>
                        </svg>
                        - No numberings found
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {sortedAndFilteredData.map((item, index) => (
                      <div
                        key={index}
                        class="bg-white dark:bg-[#1D1E24] p-4 mb-2 rounded-lg border dark:border-white dark:text-white"
                      >
                        <div class="flex justify-between mb-2">
                          <span class="font-bold">Zone</span>
                          <span>{item.zone}</span>
                        </div>
                        <div class="flex justify-between mb-2">
                          <span class="font-bold">Total Calls</span>
                          <div class="flex items-center">{item.total}</div>
                        </div>
                        <div className="flex justify-between">
                          <div class=" mb-2 text-left items-start justify-start">
                            <span class="font-bold">ASR</span>
                            <span>
                              <div className="flex items-center justify-start ml-[-10px]">
                                <PieChart width={60} height={60}>
                                  <Pie
                                    data={[
                                      { name: "Filled", value: item.answered },
                                      {
                                        name: "Empty",
                                        value: 100 - item.answered,
                                      },
                                    ]}
                                    cx={25}
                                    cy={25}
                                    innerRadius={8}
                                    outerRadius={16}
                                    fill={getColor(item.answered)}
                                    dataKey="value"
                                    startAngle={90}
                                    endAngle={-270}
                                  >
                                    <Cell
                                      key={`cell-filled`}
                                      fill={getColor(item.answered)}
                                    />
                                    <Cell key={`cell-empty`} fill="#ddd" />
                                  </Pie>
                                </PieChart>
                                {item.answered}%
                              </div>
                            </span>
                          </div>
                          <div class=" mb-2 justify-end text-right">
                            <span class="font-bold ">CRP GEO</span>
                            <span>
                              {" "}
                              <div className="flex items-center">
                                <PieChart width={60} height={60}>
                                  <Pie
                                    data={[
                                      { name: "Filled", value: item.converted },
                                      {
                                        name: "Empty",
                                        value: 100 - item.converted,
                                      },
                                    ]}
                                    cx={25}
                                    cy={25}
                                    innerRadius={8}
                                    outerRadius={16}
                                    fill={getColor(item.converted)}
                                    dataKey="value"
                                    startAngle={90}
                                    endAngle={-270}
                                  >
                                    <Cell
                                      key={`cell-filled`}
                                      fill={getColor(item.converted)}
                                    />
                                    <Cell key={`cell-empty`} fill="#ddd" />
                                  </Pie>
                                </PieChart>
                                {item.converted}%
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TableWithZoneData;
