import http from "../../http-common";

const listCustomers = () => {
  return http.get(`/api/v1/customers/list`);
};

const getAsrValues = ({ customerId }) => {
  return http.get(`/api/v1/customers/getAsrValues?customerId=${customerId}`);
};

const updateAsrValues = ({ customerId, asrValues }) => {
  return http.put(
    `/api/v1/customers/updateAsrValues?customerId=${customerId}`,
    { asrValues }
  );
};

const CustomerService = {
  listCustomers,
  getAsrValues,
  updateAsrValues,
};

export default CustomerService;
