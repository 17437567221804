// CustomerContext.js
import React, { createContext, useContext, useState } from "react";

const CustomerContext = createContext();

export const useCustomer = () => useContext(CustomerContext);

export const CustomerProvider = ({ children }) => {
  const [customerName, setCustomerName] = useState("");

  return (
    <CustomerContext.Provider value={{ customerName, setCustomerName }}>
      {children}
    </CustomerContext.Provider>
  );
};
