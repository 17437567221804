import { Routes, Route, BrowserRouter } from "react-router-dom";
import { DarkModeProvider } from "./context/DarkModeContext";
import DashboardPage from "./pages/dashboard";
import ManagementPage from "./pages/management";
import ProfilePage from "./pages/profile";
import NotFoundPage from "./pages/notFound";
import Layout from "./layout/Layout";
import LoginPage from "./pages/login";
import { AuthProvider } from "./context/AuthContext";
import RequireAuth from "./services/login/requireAuth";
import AdminPage from "./pages/admin";
import { CustomerProvider } from "./context/CustomerContext";

function App() {
  return (
    <div className="App">
      <DarkModeProvider>
        <CustomerProvider>
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <Layout>
                        <DashboardPage />
                      </Layout>
                    </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/admin"
                  element={
                    <RequireAuth requiredScope="master">
                      <Layout>
                        <AdminPage />
                      </Layout>
                    </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/dashboard"
                  element={
                    <RequireAuth>
                      <Layout>
                        <DashboardPage />
                      </Layout>
                    </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/management"
                  element={
                    <RequireAuth>
                      <Layout>
                        <ManagementPage />
                      </Layout>
                    </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/profile"
                  element={
                    <RequireAuth>
                      <Layout>
                        <ProfilePage />
                      </Layout>
                    </RequireAuth>
                  }
                ></Route>
                <Route
                  path="/not-found"
                  element={
                    <RequireAuth>
                      <Layout>
                        <NotFoundPage />
                      </Layout>
                    </RequireAuth>
                  }
                ></Route>
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </CustomerProvider>
      </DarkModeProvider>
    </div>
  );
}

export default App;
