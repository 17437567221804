import React, { useState, useEffect } from "react";
import PoolService from "../../services/pool/pool";

const Tester = ({ src, dst, setShowTester }) => {
  const [source, setSource] = useState(src || "");
  const [destination, setDestination] = useState(dst || "");
  const [isLoading, setIsLoading] = useState(false);
  const [serverOptions, setServerOptions] = useState([]);
  const [selectedServer, setSelecedServer] = useState("USA01");
  const [countdown, setCountdown] = useState(5);

  const [response, setResponse] = useState({ status: "", message: "" });

  const handleTest = () => {
    setIsLoading(true);

    setResponse({ status: "", message: "" });
    const data = {
      server: selectedServer,
      source,
      destination,
    };
    PoolService.spamTester({
      data,
    })
      .then(() => {
        setResponse({
          status: "success",
          message: `Call made succesfully`,
        });
        setIsLoading(false);
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          setIsLoading(false);
          setResponse({
            status: "error",
            message:
              message ||
              "ERROR: please contact administrator support@astroline.com",
          });
        }
      );
  };
  useEffect(() => {
    return () => {
      setCountdown(0);
    };
  }, []);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white dark:bg-gray-800 dark:text-white rounded-lg shadow-lg p-4 max-w-xl w-full">
        <div className="mb-4 w-full">
          <label className="text-2xl flex items-center mb-6 mr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
              />
            </svg>
            Voostester
          </label>
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
            Server
          </label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            value={selectedServer}
            onChange={(e) => setSelecedServer(e.target.value)}
          >
            <option value="assign">USA01</option>
            <option disabled value="unassign">
              USA02
            </option>
          </select>
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
            Source
          </label>
          <input
            className="rounded-l w-full px-2 font-semibold dark:text-black border-gray-400 border"
            value={source}
            onChange={(e) => setSource(e.target.value)}
          ></input>
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
            Destination - E164 format (i.e 3497371000)
          </label>
          <input
            className="rounded-l w-full px-2 font-semibold dark:text-black border-gray-400 border"
            onChange={(e) => setDestination(e.target.value)}
          ></input>
        </div>
        <div className="font-semibold text-sm mt-[1vh] mb-[1vh]">
          {response && response.status && response.message ? (
            <>
              {response.status === "error" ? (
                <div className="text-red-500">{response.message}</div>
              ) : (
                <div className="text-green-600">
                  {response.status === "success" && countdown > 0
                    ? `Call made succesfully`
                    : response.message}
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>
        <div className="justify-between w-full items-center flex mb-2 mt-5">
          <button
            onClick={() => setShowTester(false)}
            className="rounded-full dark:bg-gray-400 font-semibold py-1 px-6 text-xl"
          >
            Close
          </button>
          <button
            onClick={handleTest}
            className={`rounded-full font-semibold dark:text-white py-1 px-6 text-xl ${
              isLoading ? "dark:bg-gray-500" : "dark:bg-gray-700"
            }`}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div
                  className="spinner-border animate-spin inline-block w-5 h-5 border-2 rounded-full"
                  role="status"
                ></div>
                <span className="visually-hidden ml-3">Loading...</span>
              </div>
            ) : (
              "Call"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tester;
