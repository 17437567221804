import ManagementMain from "../components/management/ManagementMain";

const ManagementPage = () => {
  return (
    <>
      <ManagementMain />
    </>
  );
};
export default ManagementPage;
