import http from "../../http-common";

const asr = ({ customerId, data }) => {
  return http.post(`/api/v1/stats/asr?customerId=${customerId}`, data);
};

const crp = ({ customerId, data }) => {
  return http.post(`/api/v1/stats/crp?customerId=${customerId}`, data);
};

const crpGeo = ({ customerId, data }) => {
  return http.post(`/api/v1/stats/crpGeo?customerId=${customerId}`, data);
};

const top100cliAndCodes = ({ customerId, data, isChecked }) => {
  return http.post(
    `/api/v1/stats/top100cliAndCodes?customerId=${customerId}&top10checked=${isChecked}`,
    data
  );
};

const dataByZone = ({ customerId, data }) => {
  return http.post(`/api/v1/stats/dataByZone?customerId=${customerId}`, data);
};

const top10campaigns = ({ customerId, data }) => {
  return http.post(
    `/api/v1/stats/top10campaigns?customerId=${customerId}`,
    data
  );
};

const cliData = ({ customerId, data }) => {
  return http.post(`/api/v1/stats/cliData?customerId=${customerId}`, data);
};

const getCallCodesByCli = ({ customerId, data }) => {
  return http.post(
    `/api/v1/stats/getCallCodesByCli?customerId=${customerId}`,
    data
  );
};

const getCallAmountByCli = ({ customerId, data }) => {
  return http.post(
    `/api/v1/stats/getCallAmountByCli?customerId=${customerId}`,
    data
  );
};

const getAsrByCli = ({ customerId, data }) => {
  return http.post(`/api/v1/stats/getAsrByCli?customerId=${customerId}`, data);
};
const StatsService = {
  asr,
  crp,
  crpGeo,
  top100cliAndCodes,
  dataByZone,
  top10campaigns,
  cliData,
  getCallCodesByCli,
  getCallAmountByCli,
  getAsrByCli,
};

export default StatsService;
