const Breadcrumb = ({ currentPage, onClick }) => (
  <nav className="top-0 left-0 p-4 flex h-[5vh]">
    <div className="flex items-center space-x-2 text-sm font-medium cursor-pointer text-gray-500 dark:text-white">
      <svg
        onClick={() => onClick("home")}
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5  hover:text-gray-700 dark:hover:text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        />
      </svg>
      <span
        onClick={() => onClick("home")}
        className="hover:text-gray-700 dark:hover:text-gray-400"
      >
        Management
      </span>
      {currentPage && currentPage !== "home" && (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
          <span className="capitalize">{currentPage}</span>
        </>
      )}
    </div>
  </nav>
);

export default Breadcrumb;
