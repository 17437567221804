import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import AvatarService from "../../services/avatar/avatar";

function ProfileEditor() {
  const [image, setImage] = useState();
  const [scale, setScale] = useState(1);
  const editorRef = useRef(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
        setIsImageLoaded(true);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    const loadAvatar = async () => {
      const url = await AvatarService.getAvatarByEmail(
        localStorage.getItem("email")
      );
      setImage(url);
    };

    loadAvatar();
  }, []);

  const handleScaleChange = (e) => {
    setScale(parseFloat(e.target.value));
  };

  const handleSubmit = () => {
    if (editorRef.current) {
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      canvasScaled.toBlob(async (blob) => {
        const formData = new FormData();
        formData.append("avatar", blob);

        try {
          const response = await AvatarService.uploadAvatar(formData);
          console.log(response.data);
          localStorage.setItem("avatar", response.data.avatarUrl);
          window.location.reload();
        } catch (error) {
          console.error("Error uploading avatar:", error);
        }
      });
    }
  };

  return (
    <div className="flex justify-center items-center w-full h-[90vh]">
      <div className="bg-white p-6 shadow-lg rounded-xl">
        <div className="flex flex-col items-center">
          <div className="mb-4 mt-5 items-center justify-center text-center text-3xl text-gray-600 font-bold">
            {localStorage.getItem("name")}
          </div>

          <AvatarEditor
            ref={editorRef}
            image={image}
            width={250}
            height={250}
            border={50}
            borderRadius={125}
            color={[255, 255, 255, 0.6]}
            scale={scale}
            rotate={0}
          />
          <input
            className="w-3/4 appearance-none bg-gray-200 h-1 rounded mt-4"
            type="range"
            value={scale}
            min="1"
            max="2"
            step="0.01"
            onChange={handleScaleChange}
          />
          <label className="cursor-pointer mt-5 text-customBlackBackground hover:text-customBlackBackgroundHover font-medium">
            Upload Avatar
            <input
              className="hidden"
              type="file"
              onChange={handleImageChange}
            />
          </label>
        </div>

        <button
          className={`mt-10 items-end w-full ${
            isImageLoaded ? "bg-customBlackBackground" : "bg-slate-300"
          } text-white font-bold py-2 px-4 hover:customBlackBackgroundHover rounded-xl`}
          onClick={handleSubmit}
          disabled={!isImageLoaded}
        >
          Apply changes
        </button>
      </div>
    </div>
  );
}

export default ProfileEditor;
