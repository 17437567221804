import CustomersTable from "./CustomersTable";

const CustomersMain = () => {
  return (
    <>
      <CustomersTable />
    </>
  );
};

export default CustomersMain;
