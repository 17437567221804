import React, { useEffect, useState } from "react";
import Pagination from "../../shared/Pagination";
import { Link } from "react-router-dom";

import {
  PencilSquareIcon,
  PlusCircleIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";

import CustomerService from "../../../services/customers/customers";
import CustomerAdd from "./CustomersAdd";
import CustomersEdit from "./CustomersEdit";
import { useCustomer } from "../../../context/CustomerContext";
const CustomersTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(27);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showEditCustomer, setShowEditCustomer] = useState(false);
  const [customerSelected, setCustomerSelected] = useState();
  const { setCustomerName } = useCustomer();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const filteredData = data.filter((item) => {
    console.log(item);
    const searchTermMatch = (item?.companyName?.toLowerCase() || "").includes(
      searchTerm.toLowerCase()
    );
    console.log(item);
    return searchTermMatch;
  });

  const totalItems = filteredData.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    CustomerService.listCustomers()
      .then(({ data: { data: customers } }) => {
        console.log(customers);
        setData(customers);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };
  const toggleAddCustomer = () => {
    setShowAddCustomer(!showAddCustomer);
  };
  const handleEditCustomer = (item) => {
    setCustomerSelected(item);
    setShowEditCustomer(true);
  };

  return (
    <div class="flex flex-col h-full w-full">
      <div className="p-4">
        <div className="flex justify-between items-start">
          <div className="flex items-center">
            <div className="relative flex">
              <button onClick={toggleAddCustomer}>
                <PlusCircleIcon className="h-6 w-6 dark:text-white" />
              </button>
              {showAddCustomer && (
                <div
                  className="fixed inset-0 bg-black bg-opacity-30 z-40"
                  style={{ backdropFilter: "blur(4px)" }}
                  onClick={() => setShowAddCustomer(false)}
                ></div>
              )}
              {showEditCustomer && (
                <CustomersEdit
                  isOpen={showEditCustomer}
                  setIsOpen={setShowEditCustomer}
                  customer={customerSelected}
                />
              )}

              {showAddCustomer && (
                <CustomerAdd setShowAddCustomer={setShowAddCustomer} />
              )}
            </div>
            <div className="w-px h-6 bg-gray-300 mx-2"></div>
          </div>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search by customer name..."
            className="input input-bordered border border-black bg-[#F7F8FC] dark:bg-gray-900 rounded-full text-sm dark:text-white px-2 py-0.5"
          />
        </div>
      </div>

      <div class="overflow-x-auto hidden sm:block">
        <table class="min-w-full table-auto dark:text-white border-b">
          <thead class="border-b sticky bg-slate-200 dark:bg-gray-700">
            <tr>
              <th class="text-left px-4 py-2">Name</th>
              <th class="text-left px-4 py-2">User Licenses</th>
              <th class="text-left px-4 py-2">Campaign Licenses</th>
              <th class="text-left px-4 py-2">Created By</th>
              <th class="text-left px-4 py-2">Created At</th>
              <th class="text-left px-4 py-2">Updated By</th>
              <th class="text-left px-4 py-2">Updated At</th>
              <th class="text-left px-4 py-2">Actions</th>
            </tr>
          </thead>
          {isLoading ? (
            <tbody>
              <tr>
                <td colSpan="100%">
                  <div className="loading-bar"></div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody class="bg-white dark:bg-gray-800">
              {currentItems.length === 0 ? (
                <tr>
                  <td colSpan="14" className="text-center py-2">
                    <div className="flex justify-center items-center w-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-mood-empty-filled mr-1"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path
                          d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 10.66h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-5.99 -5l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                          stroke-width="0"
                          fill="currentColor"
                        ></path>
                      </svg>
                      - No customers found
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {currentItems.map((item, index) => (
                    <tr
                      key={index}
                      class="border-b dark:border-gray-700 text-xs font-medium"
                    >
                      <td class="px-4 py-2">{item.companyName}</td>
                      <td class="px-4 py-2">{item.userLicenses}</td>
                      <td class="px-4 py-2">{item.campaignLicences}</td>
                      <td class="px-4 py-2">{item.createdBy}</td>
                      <td class="px-4 py-2">
                        {new Date(item.createdAt).toLocaleString(undefined, {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </td>
                      <td class="px-4 py-2">{item.updatedBy}</td>
                      <td class="px-4 py-2">
                        {new Date(item.updatedAt).toLocaleString(undefined, {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </td>
                      <td className="px-4 py-2 items-start justify-start flex">
                        <Link
                          onClick={() => setCustomerName(item.companyName)}
                          to={`/dashboard?delegatedOrg=${item._id}`}
                          aria-current="page"
                        >
                          <EyeIcon className="h-5 w-5 mx-2 cursor-pointer" />
                        </Link>
                        <PencilSquareIcon
                          className="h-5 w-5 mx-2 cursor-pointer"
                          onClick={() => handleEditCustomer(item)}
                        />
                        {/* <TrashIcon
                          className="h-5 w-5 mx-2 cursor-pointer"
                          onClick={() => {
                            setShowCampaignsDelete(true);
                            // Similar al update, asegúrate de establecer cuál es el ítem seleccionado para la eliminación.
                            setSelectedCampaign(item); // Asume que esta función ajusta el estado con el ítem a eliminar.
                          }}
                        /> */}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          )}
        </table>
      </div>

      <div class="sm:hidden overflow-y-scroll">
        {isLoading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <div className="loading-bar"></div>
              </td>
            </tr>
          </tbody>
        ) : (
          <>
            {currentItems.length === 0 ? (
              <tr className="">
                <td colSpan="1" className="text-center py-2">
                  <div className="flex justify-center  items-center w-full dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-mood-empty-filled mr-1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 10.66h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-5.99 -5l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                        stroke-width="0"
                        fill="currentColor"
                      ></path>
                    </svg>
                    - No campaigns found
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {/* {currentItems.map((item, index) => (
                  <div
                    key={index}
                    class="bg-white dark:bg-gray-900 p-4 mb-2 rounded-lg shadow dark:text-white"
                  >
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Name:</span>
                      <span>{item.name}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Description:</span>
                      <span>{item.description}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">CLI Received:</span>
                      <span>{item.cliReceived}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Pool Amount:</span>
                      <span>{item.pool.length}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Color:</span>
                      <span
                        key={item.color}
                        style={{ backgroundColor: item.color }}
                        className="inline-flex items-center px-2  text-xs rounded-full font-medium text-black dark:text-white"
                      >
                        {item.color}
                      </span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Created By:</span>
                      <span>{item.createdBy}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Created At:</span>
                      <span>
                        {new Date(item.createdAt).toLocaleString(undefined, {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Updated By:</span>
                      <span>{item.updatedBy}</span>
                    </div>
                    <div class="flex justify-between">
                      <span class="font-bold">Updated At:</span>
                      <span>
                        {new Date(item.updatedAt).toLocaleString(undefined, {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </span>
                    </div>
                  </div>
                ))} */}
              </>
            )}
          </>
        )}
      </div>

      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setItemsPerPage={setItemsPerPage}
      />
    </div>
  );
};
export default CustomersTable;
