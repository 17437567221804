import { useEffect, useState } from "react";
import useCustomerId from "../../hooks/useCustomerId";
import CliDataTable from "./CliDataTable";
import CliDataDetails from "./ClliDataDetails";
import { useDarkMode } from "../../context/DarkModeContext";
import { EuiThemeProvider } from "@elastic/eui";
import FiltersCampaignsOnly from "../shared/FiltersCampaignsOnly";
import { FaFilter } from "react-icons/fa";
import { EuiSuperDatePicker } from "@elastic/eui";

const CliDataMain = () => {
  const customerId = useCustomerId();
  const [startDate, setStartDate] = useState("now-1M");
  const [endDate, setEndDate] = useState("now");
  const [filters, setFilters] = useState({ zones: [], campaigns: [] });
  const [showFilters, setShowFilters] = useState(false);
  const { darkMode } = useDarkMode();

  const [cliSelected, setCliSelected] = useState();

  useEffect(() => {
    setCliSelected();
  }, []);

  const clearFilters = () => {
    setFilters({
      zones: [],
      campaigns: [],
    });
  };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const numberOfFiltersApplied = Object.values(filters).reduce(
    (totalLength, value) => {
      if (Array.isArray(value)) {
        return totalLength + value.length;
      }

      return totalLength + (value !== null ? 1 : 0);
    },
    0
  );

  const onTimeChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    const themeLight = "/white.css";
    const themeDark = "/dark.css";

    const loadTheme = (themePath) => {
      const head = document.head;
      const link = document.createElement("link");

      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = themePath;

      const existingLink = document.getElementById("theme-style");
      if (existingLink) {
        head.removeChild(existingLink);
      }

      link.id = "theme-style";
      head.appendChild(link);
    };

    if (darkMode) {
      loadTheme(themeDark);
    } else {
      loadTheme(themeLight);
    }

    return () => {
      const existingLink = document.getElementById("theme-style");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [darkMode, customerId]);

  return (
    <div className="w-full gap-3 dark:text-white">
      <div>
        <div className="border-b-2 dark:text-white h-[7vh] p-3 flex justify-between items-center content-center">
          <div className="relative flex">
            <button onClick={toggleFilters} className="z-50" title="Filters">
              <FaFilter className="dark:text-white" />
            </button>

            {showFilters && (
              <div
                className="fixed inset-0 bg-black bg-opacity-30 z-40"
                style={{ backdropFilter: "blur(4px)" }}
                onClick={() => setShowFilters(false)}
              ></div>
            )}
            {showFilters && (
              <FiltersCampaignsOnly
                showFilters={showFilters}
                filters={filters}
                setFilters={setFilters}
                setShowFilters={setShowFilters}
                selectedZones={filters.zones}
                selectedCampaigns={filters.campaigns}
              />
            )}
            {numberOfFiltersApplied > 0 && (
              <div className="flex">
                <div className="w-px h-6 bg-gray-300 mx-2"></div>
                <button
                  className="mr-4 text-xs text-white bg-red-900 rounded-xl px-2"
                  onClick={clearFilters}
                >
                  Clear filters
                </button>
              </div>
            )}
          </div>
          <div>
            <EuiThemeProvider colorMode={darkMode ? "dark" : "light"}>
              <EuiSuperDatePicker
                start={startDate}
                end={endDate}
                onTimeChange={onTimeChange}
              />
            </EuiThemeProvider>
          </div>
        </div>
      </div>
      <div className="h-[88vh] flex gap-3 p-3 ">
        <div className="bg-gray-200  dark:bg-[#1D1E24] h-full w-3/5 rounded-xl">
          <CliDataTable
            customerId={customerId}
            startDate={startDate}
            endDate={endDate}
            filters={filters}
            setCliSelected={setCliSelected}
          />
        </div>
        <div className="bg-gray-200  dark:bg-[#1D1E24] h-full w-2/5 rounded-xl">
          {cliSelected ? (
            <CliDataDetails
              cliSelected={cliSelected}
              startDate={startDate}
              endDate={endDate}
            />
          ) : (
            <>
              <div className="h-full w-full rounded-xl items-center justify-center flex dark:bg-[#1D1E24] bg-white">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-20 h-20 mr-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6"
                    />
                  </svg>
                </div>
                <span className="flex">
                  Please select a number{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mr-2 ml-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                    />
                  </svg>
                  in order to display the data
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default CliDataMain;
