import ProfileEditor from "../components/profile/Profile";

const ProfilePage = () => {
  return (
    <>
      <ProfileEditor />
    </>
  );
};

export default ProfilePage;
