import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import UserService from "../../../services/customers/users";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

const animatedComponents = makeAnimated();

const customStyles = {
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
    rounded: "full",
    borderColor: "#d1d5db",
    "&:hover": {
      borderColor: "#d1d5db",
    },
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#eff6ff",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#1e40af",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#1e40af",
    "&:hover": {
      backgroundColor: "#1e40af",
      color: "white",
    },
  }),
};

const CustomersEdit = ({ isOpen, setIsOpen, customer }) => {
  const [activeTab, setActiveTab] = useState("details");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "details":
        return (
          <div>
            <Details customer={customer} />
          </div>
        );
      case "users":
        return (
          <div>
            <Users
              customerId={customer._id}
              userLicenses={customer.userLicenses}
            />
          </div>
        );
      case "pool":
        return <div>TODO</div>;
      case "logs":
        return <div>TODO</div>;
      default:
        return <div>TODO</div>;
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex text-sm font-medium">
      <div className="flex-1 backdrop-blur-sm"></div>
      <div className="w-1/2 h-full flex flex-col bg-white dark:bg-gray-900">
        <div className="dark:text-white">
          <h1 className="text-xl font-bold py-4 px-4 dark:text-white flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
              />
            </svg>
            {customer.companyName}
          </h1>
          <div className="w-full">
            <div className="flex my-4 w-full">
              {["details", "users", "pool", "logs"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => handleTabClick(tab)}
                  className={`w-1/4 pb-2 pt-1 px-4 ${
                    activeTab === tab
                      ? "border-b-2 border-gray-500 dark:border-white text-gray-600 dark:text-white bg-gray-100 dark:bg-gray-800"
                      : "text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                  } focus:outline-none`}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
          </div>
          <div className="flex-1 overflow-auto">{renderTabContent()}</div>
        </div>
        <button
          onClick={() => setIsOpen(false)}
          className="p-4 dark:bg-gray-500 text-white w-full text-center mt-auto "
        >
          Close
        </button>
      </div>
    </div>
  );
};

const Details = ({ customer }) => {
  return (
    <p className="p-4 text-gray-600 dark:text-gray-300">
      <div className="text-gray-600 dark:text-gray-300">
        <div className="flex flex-col gap-4">
          <p>
            <strong>Name:</strong> {customer.companyName}
          </p>
          <p>
            <strong>VAT NUM:</strong> {customer.vatNum}
          </p>
          <p>
            <strong>Post Code:</strong> {customer.postCode}
          </p>
          <p>
            <strong>Contact Email:</strong> {customer.contactEmail}
          </p>
          <p>
            <strong>Contact First Name:</strong> {customer.contactFirstName}
          </p>
          <p>
            <strong>Contact Last Name:</strong> {customer.contactLastName}
          </p>
          <p>
            <strong>Contact Phone:</strong> {customer.contactPhone}
          </p>
          <p>
            <strong>Domain:</strong> {customer.domain}
          </p>
          <p>
            <strong>User Licenses:</strong> {customer.userLicenses}
          </p>
          <p>
            <strong>Campaign Licenses:</strong> {customer.campaignLicenses}
          </p>
          <p>
            <strong>Created By:</strong> {customer.createdBy}
          </p>
          <p>
            <strong>Created At:</strong>{" "}
            {new Date(customer.createdAt).toLocaleDateString()}{" "}
            {new Date(customer.createdAt).toLocaleTimeString()}
          </p>
          <p>
            <strong>Updated By:</strong> {customer.updatedBy}
          </p>
          <p>
            <strong>Updated At:</strong>{" "}
            {new Date(customer.updatedAt).toLocaleDateString()}{" "}
            {new Date(customer.updatedAt).toLocaleTimeString()}
          </p>
        </div>
      </div>
    </p>
  );
};

const Users = ({ customerId, userLicenses }) => {
  const [showAddUser, setShowAddUser] = useState(false);
  const [users, setUsers] = useState([]);
  const roleStyle = (roles, roleName) => {
    const hasReadWrite = roles.some((role) => role === `${roleName}.ReadWrite`);
    const hasRead = roles.some((role) => role === `${roleName}.Read`);

    if (hasReadWrite) {
      return "bg-green-500";
    } else if (hasRead) {
      return "bg-blue-500";
    }
    return "bg-white";
  };

  const toggleAddCustomer = () => {
    setShowAddUser(!showAddUser);
  };

  useEffect(() => {
    UserService.listUsers({ customerId })
      .then(({ data: { data: users } }) => {
        setUsers(users);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [customerId, showAddUser]);

  const availableLicenses = parseInt(userLicenses) - users.length;
  return (
    <div className="flex flex-col h-full w-full text-xs">
      <div className="flex justify-between mb-1 pr-1 items-end">
        <div className="pl-2">
          <button onClick={toggleAddCustomer} className="flex items-center">
            <PlusCircleIcon className="h-6 w-6 dark:text-white mr-1" /> Add User
          </button>
          {showAddUser && (
            <div
              className="fixed inset-0 bg-black bg-opacity-30 z-40"
              style={{ backdropFilter: "blur(4px)" }}
              onClick={() => setShowAddUser(false)}
            ></div>
          )}
          {showAddUser && (
            <AddUser
              setShowAddUser={setShowAddUser}
              availableLicenses={availableLicenses}
              customerId={customerId}
            />
          )}
        </div>
        <div>
          <div className="flex">
            <div className={`h-3 w-3 rounded-full bg-green-500 mr-1`}></div>
            Read and Write Permissions
          </div>
          <div className="flex">
            <div className={`h-3 w-3 rounded-full bg-blue-500 mr-1`}></div>
            Read Permissions
          </div>
          <div className="flex">
            <div className={`h-3 w-3 rounded-full bg-white mr-1`}></div>
            Without Permissions
          </div>
        </div>
      </div>
      <div class="overflow-x-auto hidden sm:block">
        <table class="min-w-full table-auto dark:text-white border-b">
          <thead class="border-b sticky bg-slate-200 dark:bg-gray-700 text-xs">
            <tr>
              <th class="text-left px-4 py-2">Email</th>
              <th class="text-left px-4 py-2">Name</th>
              <th class="text-left px-4 py-2">Created/Edited By</th>
              <th class="text-left px-4 py-2">Created At</th>
              <th class="text-left px-4 py-2">Updated At</th>
              <th class="text-left px-4 py-2">User</th>
              <th class="text-left px-4 py-2">Campaign</th>
              <th class="text-left px-4 py-2">Numbering</th>
              <th class="text-left px-4 py-2">Stats</th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700 text-xs">
            {users.map((user) => (
              <tr key={user._id}>
                <td className="px-4 py-2 whitespace-nowrap  text-gray-500 dark:text-gray-300">
                  {user.email}
                </td>
                <td className="px-4 py-2 whitespace-nowrap  text-gray-500 dark:text-gray-300">{`${user.name} ${user.surname1}`}</td>
                <td className="px-4 py-2 whitespace-nowrap  text-gray-500 dark:text-gray-300">
                  {user.createdBy}
                </td>
                <td className="px-4 py-2 whitespace-nowrap  text-gray-500 dark:text-gray-300">
                  {new Date(user.createdAt).toLocaleDateString()}
                </td>
                <td className="px-4 py-2 whitespace-nowrap  text-gray-500 dark:text-gray-300">
                  {new Date(user.updatedAt).toLocaleDateString()}
                </td>
                <td className="px-4 py-2">
                  <div
                    className={`h-3 w-3 rounded-full ${roleStyle(
                      user.roles,
                      "User"
                    )}`}
                  ></div>
                </td>
                <td className="px-4 py-2">
                  <div
                    className={`h-3 w-3 rounded-full ${roleStyle(
                      user.roles,
                      "Campaign"
                    )}`}
                  ></div>
                </td>
                <td className="px-4 py-2">
                  <div
                    className={`h-3 w-3 rounded-full ${roleStyle(
                      user.roles,
                      "Numbering"
                    )}`}
                  ></div>
                </td>
                <td className="px-4 py-2">
                  <div
                    className={`h-3 w-3 rounded-full ${roleStyle(
                      user.roles,
                      "Stats"
                    )}`}
                  ></div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const AddUser = ({ setShowAddUser, availableLicenses, customerId }) => {
  const [name, setName] = useState();
  const [surName1, setSurname1] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [roles, setRoles] = useState([]);
  const [countdown, setCountdown] = useState(5);
  const [isLoading, setIsLoading] = useState(false);

  const [response, setResponse] = useState({ status: "", message: "" });

  const roleOptions = [
    {
      value: "User.Read",
      label: (
        <span className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-black dark:text-white bg-gray-600">
          User.Read
        </span>
      ),
    },
    {
      value: "User.ReadWrite",
      label: (
        <span className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-black dark:text-white bg-gray-600">
          User.ReadWrite
        </span>
      ),
    },
    {
      value: "Campaign.Read",
      label: (
        <span className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-black dark:text-white bg-gray-600">
          Campaign.Read
        </span>
      ),
    },
    {
      value: "Campaign.ReadWrite",
      label: (
        <span className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-black dark:text-white bg-gray-600">
          Campaign.ReadWrite
        </span>
      ),
    },
    {
      value: "Numbering.Read",
      label: (
        <span className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-black dark:text-white bg-gray-600">
          Numbering.Read
        </span>
      ),
    },
    {
      value: "Numbering.ReadWrite",
      label: (
        <span className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-black dark:text-white bg-gray-600">
          Numbering.ReadWrite
        </span>
      ),
    },
    {
      value: "Numbering.Read",
      label: (
        <span className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-black dark:text-white bg-gray-600">
          Numbering.Read
        </span>
      ),
    },
    {
      value: "Stats.Read",
      label: (
        <span className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-black dark:text-white bg-gray-600">
          Stats.Read
        </span>
      ),
    },
  ];

  const handleRoleChange = (selectedOptions) => {
    setRoles(selectedOptions);
  };

  const handleSave = () => {
    const data = {
      name,
      surname1: surName1,
      email,
      password,
      roles: roles?.map((role) => role.value) || [],
    };
    UserService.createUser({ customerId, data })
      .then(() => {
        setResponse({
          status: "success",
          message: `User created succesfully successfully`,
        });
        setIsLoading(false);
        const intervalId = setInterval(() => {
          setCountdown((currentCountdown) => {
            if (currentCountdown <= 1) {
              clearInterval(intervalId);
              setShowAddUser(false);
              return 0;
            }
            return currentCountdown - 1;
          });
        }, 1000);
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          setIsLoading(false);
          setResponse({
            status: "error",
            message:
              message ||
              "ERROR: please contact administrator support@astroline.com",
          });
        }
      );
  };
  return (
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 max-w-lg w-full">
        <div className=" gap-4 w-full">
          <div className="mb-4 w-full">
            <label className="text-2xl font-semibold  dark:text-white flex items-center mb-6">
              Create new User
            </label>
            {availableLicenses === 0 ? (
              <div>No licenses available for this customer</div>
            ) : (
              <div className="w-full">
                <div className="flex  gap-4">
                  <div>
                    <label className="text-sm font-semibold block mb-2 dark:text-white">
                      Name
                    </label>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="rounded-xl w-full px-2 font-semibold dark:text-black"
                    ></input>
                  </div>
                  <div>
                    <label className="text-sm font-semibold block mb-2 dark:text-white">
                      Last Name
                    </label>
                    <input
                      value={surName1}
                      onChange={(e) => setSurname1(e.target.value)}
                      className="rounded-xl w-full px-2 font-semibold dark:text-black"
                    ></input>
                  </div>
                  <div></div>
                </div>
                <div className="mt-2 gap-4">
                  <div className="w-2/4">
                    <label className="text-sm font-semibold block mb-2 dark:text-white">
                      E-Mail
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="rounded-xl w-full px-2 font-semibold dark:text-black"
                    ></input>
                  </div>
                  <div className="flex mt-2 gap-4">
                    <div>
                      <label className="text-sm font-semibold block mb-2 dark:text-white">
                        Password
                      </label>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="rounded-xl w-full px-2 font-semibold dark:text-black"
                      ></input>
                    </div>
                    <div>
                      <label className="text-sm font-semibold block mb-2 dark:text-white">
                        Repeat Password
                      </label>
                      <input
                        type="password"
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        className="rounded-xl w-full px-2 font-semibold dark:text-black"
                      ></input>
                    </div>
                  </div>
                  <div className="mt-2">
                    <label className="text-sm font-semibold block mb-2 dark:text-white">
                      Roles
                    </label>
                    <Select
                      placeholder="Select Roles"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={roleOptions}
                      className="text-sm"
                      styles={customStyles}
                      onChange={handleRoleChange}
                      value={roles}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="font-semibold text-sm mt-[6vh]">
              {response && response.status && response.message ? (
                <>
                  {response.status === "error" ? (
                    <div className="text-red-500">{response.message}</div>
                  ) : (
                    <div className="text-green-600">
                      {response.status === "success" && countdown > 0
                        ? `User added successfully: closing in ${countdown} second(s)...`
                        : response.message}
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="flex justify-between mt-10">
              <button
                className="px-4 py-2 bg-gray-500 text-white text-sm rounded font-medium  hover:bg-gray-600"
                onClick={() => setShowAddUser(false)}
              >
                Close
              </button>
              <button
                onClick={handleSave}
                className={`rounded font-semibold dark:text-white py-2 px-4 text-sm ${
                  isLoading ? "dark:bg-gray-500" : "dark:bg-gray-700"
                }`}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <div
                      className="spinner-border animate-spin inline-block w-5 h-5 border-2 rounded-full"
                      role="status"
                    ></div>
                    <span className="visually-hidden ml-3">Loading...</span>
                  </div>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersEdit;
