import NavBar from "../components/shared/NavBar";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <NavBar className="" />
      <div className="flex flex-grow bg-[#F7F8FC] dark:bg-gray-700 min-h-0 w-full">
        {children}
      </div>
    </div>
  );
};

export default Layout;
