import http from "../../http-common";

const listUsers = ({ customerId }) => {
  return http.get(`/api/v1/users/list?customerId=${customerId}`);
};

const createUser = ({ customerId, data }) => {
  return http.post(`/api/v1/users/create?customerId=${customerId}`, data);
};

const UserService = {
  listUsers,
  createUser,
};

export default UserService;
