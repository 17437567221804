const BadgeNewFeature = ({ text }) => {
  return (
    <div class="relative">
      <span class="absolute -top-1 -right-16 bg-red-500 text-white text-xs font-semibold px-2 py-0.5 rounded-full shadow-md">
        NEW
      </span>
      {text}
    </div>
  );
};

export default BadgeNewFeature;
