import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useState, useRef, useEffect, Fragment } from "react";
import CampaignService from "../../services/campaigns/campaigns";
import PoolService from "../../services/pool/pool";
import { FaFilter } from "react-icons/fa";
import useCustomerId from "../../hooks/useCustomerId";

function useOutsideAlerter(ref, onClose) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClose]);
}

const animatedComponents = makeAnimated();

const customStyles = {
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
    rounded: "full",
    borderColor: "#d1d5db",
    "&:hover": {
      borderColor: "#d1d5db",
    },
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#eff6ff",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#1e40af",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#1e40af",
    "&:hover": {
      backgroundColor: "#1e40af",
      color: "white",
    },
  }),
};

const FiltersZonesAndCampaigns = ({
  filters,
  setFilters,
  setShowFilters,
  selectedZones,
  selectedCampaigns,
}) => {
  const wrapperRef = useRef(null);
  const customerId = useCustomerId();
  useOutsideAlerter(wrapperRef, () => setShowFilters(false));

  const [zoneOptions, setZoneOptions] = useState([]);

  const [campaignOptions, setCampaignOptions] = useState([]);

  const handleZonesChange = (selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      zones: selectedOptions || [],
    }));
  };
  const handleCampaignsChange = (selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      campaigns: selectedOptions || [],
    }));
  };

  useEffect(() => {
    CampaignService.listCampaigns({ customerId })
      .then(
        ({
          data: {
            data: { campaigns },
          },
        }) => {
          const campaignsToPush = [];
          for (let campaign of campaigns) {
            campaignsToPush.push({
              value: campaign.name,
              label: (
                <span
                  style={{ backgroundColor: campaign.color }}
                  className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-black dark:text-white"
                >
                  {campaign.name}
                </span>
              ),
            });
          }
          setCampaignOptions(campaignsToPush);
        }
      )
      .catch((error) => {
        console.log(error);
      });

    PoolService.getZones()
      .then(({ data: { data: zones } }) => {
        const zonesToPush = [];
        for (let zone of zones) {
          zonesToPush.push({
            value: zone,
            label: zone,
          });
        }
        setZoneOptions(zonesToPush);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [customerId]);

  return (
    <>
      <div class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 max-w-lg w-full">
          <div className=" gap-4 w-full">
            <div className="mb-4 w-full">
              <label className="text-2xl font-semibold  dark:text-white flex items-center mb-6">
                <FaFilter className="dark:text-white mr-2" />
                Filters
              </label>
              <label className="text-sm font-semibold block mb-2 dark:text-white">
                Zones
              </label>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={zoneOptions}
                className="text-sm"
                styles={customStyles}
                onChange={handleZonesChange}
                value={selectedZones}
              />
            </div>

            <div className="mb-4">
              <label className="text-sm font-semibold block mb-2 dark:text-white">
                Campaigns
              </label>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={campaignOptions}
                className="text-sm"
                styles={customStyles}
                onChange={handleCampaignsChange}
                value={selectedCampaigns}
              />
              <div className="flex justify-between mt-10">
                <button
                  className="px-4 py-2 bg-gray-500 text-white text-sm rounded font-medium  hover:bg-gray-600"
                  onClick={() => setShowFilters(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltersZonesAndCampaigns;
