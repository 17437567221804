import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const useCustomerId = () => {
  const [customerId, setCustomerId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      const isAdmin = decoded.scps && decoded.scps.includes("master");
      const params = new URLSearchParams(location.search);
      const delegatedOrg = params.get("delegatedOrg");

      if (isAdmin && delegatedOrg) {
        setCustomerId(delegatedOrg);
      } else {
        setCustomerId(decoded.customerId);
      }
    }
  }, [location]);

  return customerId;
};

export default useCustomerId;
