import { useState } from "react";
import Breadcrumb from "../shared/Breadcrumb";
import CampaignsMain from "./campaigns/CampaignsMain";
import PoolMain from "./pool/PoolMain";
import useCustomerId from "../../hooks/useCustomerId";

const Panel = ({ title, icon, onClick }) => (
  <div className="flex items-center justify-center" onClick={onClick}>
    <div className="h-[20vh] w-[100vh] cursor-pointer flex flex-col items-center justify-center max-w-xs p-4 text-center bg-white dark:bg-[#1D1E24] dark:text-white rounded-xl shadow-xl hover:bg-gray-100">
      {icon}
      <h2 className="mb-3 text-4xl mt-6 font-semibold text-gray-900 dark:text-white">
        {title}
      </h2>
    </div>
  </div>
);

const ManagementMain = () => {
  const [currentPage, setCurrentPage] = useState("home");
  const customerId = useCustomerId();
  const navigate = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="relative h-[95vh] w-full">
      <Breadcrumb
        items={["Home"]}
        onClick={navigate}
        currentPage={currentPage}
      />
      <div className="flex items-center justify-center h-[90vh]">
        {currentPage === "home" && (
          <div className="flex flex-col md:flex-row items-center justify-center gap-4 h-full w-full">
            <Panel
              title="Campaigns"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-20 h-20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                  />
                </svg>
              }
              onClick={() => navigate("campaigns")}
            />
            <Panel
              title="Numbering Pool"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-20 h-20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                  />
                </svg>
              }
              onClick={() => navigate("pool")}
            />
          </div>
        )}
        {currentPage === "campaigns" && (
          <CampaignsMain customerId={customerId} />
        )}
        {currentPage === "pool" && <PoolMain customerId={customerId} />}
      </div>
    </div>
  );
};

export default ManagementMain;
