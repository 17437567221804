import PoolTable from "./PoolTable";

const PoolMain = ({ customerId }) => {
  return (
    <div className="h-full w-full">
      <PoolTable customerId={customerId} />
    </div>
  );
};
export default PoolMain;
