import http from "../../http-common";

const getAvatarByEmail = (email) => {
  return http
    .get(`/api/v1/users/getAvatarByEmail?email=${email}`, {
      responseType: "blob",
    })
    .then((response) => {
      if (response.data) {
        return URL.createObjectURL(response.data);
      } else {
        return "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Picture.png";
      }
    })
    .catch((error) => {
      console.error("Error fetching avatar:", error);
      return "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Picture.png";
    });
};

const uploadAvatar = (data) => {
  return http.post(`/api/v1/users/uploadAvatar`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const AvatarService = {
  uploadAvatar,
  getAvatarByEmail,
};

export default AvatarService;
