import React from "react";

const AvatarComponent = ({ width, height, avatarUrl }) => {
  return (
    <img
      className={`w-${width ? width : "11"} h-${
        height ? height : "11"
      } rounded-full`}
      src={avatarUrl || ""}
      alt="Avatar"
    />
  );
};

export default AvatarComponent;
