import CampaignsTable from "./CampaignsTable";

const CampaignsMain = ({ customerId }) => {
  return (
    <div className="h-full w-full">
      <CampaignsTable customerId={customerId} />
    </div>
  );
};
export default CampaignsMain;
