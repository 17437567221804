import React, { useState, useEffect } from "react";
import Select from "react-select";
import PoolService from "../../../services/pool/pool";
import CampaignService from "../../../services/campaigns/campaigns";

const PoolAssignUnassign = ({
  setShowAssignUnassign,
  poolSelected,
  customerId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [action, setAction] = useState("assign");
  const [countdown, setCountdown] = useState(5);

  const [response, setResponse] = useState({ status: "", message: "" });

  useEffect(() => {
    CampaignService.listCampaigns({ customerId })
      .then(
        ({
          data: {
            data: { campaigns },
          },
        }) => {
          const options = campaigns.map((campaign) => ({
            value: campaign._id,
            label: campaign.name,
            color: campaign.color,
          }));
          setCampaignOptions(options);
        }
      )
      .catch((error) => {
        console.log(error);
      });
  }, [customerId]);

  const handleSave = () => {
    if (!selectedCampaign) {
      setResponse({ status: "error", message: "Please select a campaign." });
      return;
    }

    const serviceMethod =
      action === "assign" ? PoolService.bulkAssign : PoolService.bulkUnassign;
    const actionText = action === "assign" ? "assigned" : "unassigned";

    setIsLoading(true);
    setResponse({ status: "", message: "" });

    serviceMethod({
      campaignId: selectedCampaign.value,
      numberingIds: poolSelected,
      customerId: customerId,
    })
      .then(() => {
        setResponse({
          status: "success",
          message: `Numberings ${actionText} to campaign successfully`,
        });
        setIsLoading(false);
        const intervalId = setInterval(() => {
          setCountdown((currentCountdown) => {
            if (currentCountdown <= 1) {
              clearInterval(intervalId);
              setShowAssignUnassign(false);
              return 0;
            }
            return currentCountdown - 1;
          });
        }, 1000);
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          setIsLoading(false);
          setResponse({
            status: "error",
            message:
              message ||
              "ERROR: please contact administrator support@astroline.com",
          });
        }
      );
  };
  useEffect(() => {
    return () => {
      setCountdown(0);
    };
  }, []);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white dark:bg-gray-800 dark:text-white rounded-lg shadow-lg p-4 max-w-xl w-full">
        <div className="mb-4 w-full">
          <label className="text-2xl flex items-center mb-6 mr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3"
              />
            </svg>
            Updating numberings
          </label>
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
            Select action:
          </label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            value={action}
            onChange={(e) => setAction(e.target.value)}
          >
            <option value="assign">Assign to Campaign</option>
            <option value="unassign">Unassign from Campaign</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
            Select campaign:
          </label>
          <Select
            options={campaignOptions}
            value={selectedCampaign}
            onChange={setSelectedCampaign}
            getOptionLabel={(option) => (
              <span
                style={{ backgroundColor: option.color }}
                className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-black dark:text-white"
              >
                {option.label}
              </span>
            )}
          />
        </div>
        <div className="font-semibold text-sm mt-[6vh]">
          {response && response.status && response.message ? (
            <>
              {response.status === "error" ? (
                <div className="text-red-500">{response.message}</div>
              ) : (
                <div className="text-green-600">
                  {response.status === "success" && countdown > 0
                    ? `Numberings ${action} to campaign successfully: closing in ${countdown} second(s)...`
                    : response.message}
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>
        <div className="justify-between w-full items-center flex mt-2">
          <button
            onClick={() => setShowAssignUnassign(false)}
            className="rounded-full dark:bg-gray-400 font-semibold py-1 px-6 text-xl"
          >
            Close
          </button>
          <button
            onClick={handleSave}
            className={`rounded-full font-semibold dark:text-white py-1 px-6 text-xl ${
              isLoading ? "dark:bg-gray-500" : "dark:bg-gray-700"
            }`}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div
                  className="spinner-border animate-spin inline-block w-5 h-5 border-2 rounded-full"
                  role="status"
                ></div>
                <span className="visually-hidden ml-3">Loading...</span>
              </div>
            ) : (
              "Apply"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PoolAssignUnassign;
