import React from "react";
import { useState, useEffect, memo } from "react";
import { SketchPicker } from "react-color";
import CampaignService from "../../../services/campaigns/campaigns";
import PoolService from "../../../services/pool/pool";
import DualListDragDrop from "../../shared/DragAndDropNumberings";
const DualListDragDropMemoized = memo(DualListDragDrop);

const CampaignsAdd = ({ setShowCampaigns, licensesAvailable, customerId }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [cliReceived, setCliReceived] = useState("");
  const [strategy, setStrategy] = useState("randomCLI");
  const [numberingOptions, setNumberingOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [includeUsedNumberings, setIncludeUsedNumberings] = useState(false);
  const [poolLoading, setPoolLoading] = useState(true);

  const [numberingSelected, setNumberingSelected] = useState([]);
  const [color, setColor] = useState("#fff");
  const [response, setResponse] = useState({ status: "", message: "" });
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const handleIncludeUsedNumberingsChange = () => {
    setIncludeUsedNumberings(!includeUsedNumberings);
  };

  const handleChangeComplete = (color) => {
    setColor(color.hex);
  };

  const handleStrategyChange = (strategy) => {
    setStrategy(strategy);
  };

  const handleSave = () => {
    setResponse({ status: "", message: "" });
    setIsLoading(true);
    const dataToPost = {
      name,
      description,
      cliReceived,
      strategy,
      color,
      // pool: numberingSelected.map((option) => option.id),
    };
    CampaignService.addCampaign({ customerId, data: dataToPost })
      .then(({ data: { status, message } }) => {
        setResponse({ status, message: "Campaign added successfully" });
        setIsLoading(false);
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          setIsLoading(false);
          setResponse({
            status: "error",
            message:
              message ||
              "ERROR: please contact adminisitrator support@astroline.com",
          });
        }
      );
  };

  useEffect(() => {
    const numberingsToOptions = [];
    setPoolLoading(true);

    PoolService.listNumberings({ customerId })
      .then(({ data: { data: numberings } }) => {
        for (let { id, zone, numbering, isoCode, campaigns } of numberings) {
          if (includeUsedNumberings) {
            numberingsToOptions.push({
              isoCode: isoCode,
              id: id,
              content: zone + " - " + numbering,
            });
          } else {
            if (campaigns.length < 1) {
              numberingsToOptions.push({
                isoCode: isoCode,
                id: id,
                content: zone + " - " + numbering,
              });
            }
          }
        }
        setNumberingOptions(numberingsToOptions);
        setPoolLoading(false);
      })
      .catch((error) => {
        setPoolLoading(false);
        console.log(error);
      });
  }, [includeUsedNumberings, customerId]);

  return (
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      {licensesAvailable === 0 ? (
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 max-w-xl w-full dark:text-white text-md font-semibold">
          <div className="bg-yellow-500 rounded-xl p-4">
            No licenses are available. Please contact your administrator
            support@astroline.com{" "}
          </div>
          <button
            onClick={() => setShowCampaigns(false)}
            className="rounded-full dark:bg-gray-800 font-semibold py-1 px-6 mt-4 text-xl"
          >
            Close
          </button>
        </div>
      ) : (
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 max-w-xl w-full">
          <div className=" gap-4 w-full">
            <div className="mb-4 w-full">
              <label className="text-2xl font-semibold  dark:text-white flex items-center mb-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-10 h-10 mr-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                  />
                </svg>{" "}
                Add campaign
              </label>
              <div className="flex w-full gap-4">
                <div className="w-1/3">
                  <label className="text-sm font-semibold block mb-2 dark:text-white">
                    Name
                  </label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="rounded-xl w-full px-2 font-semibold"
                  ></input>
                </div>
                <div className="w-2/3">
                  <label className="text-sm font-semibold block mb-2 dark:text-white">
                    Description
                  </label>
                  <input
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="rounded-xl w-full px-2 font-semibold"
                  ></input>
                </div>
              </div>
              <div className="flex w-full gap-4 mt-4">
                <div className="w-1/2">
                  <label className="text-sm font-semibold block mb-2 dark:text-white">
                    CLI Received
                  </label>
                  <input
                    value={cliReceived}
                    onChange={(e) => setCliReceived(e.target.value)}
                    className="rounded-xl w-full px-2 font-semibold"
                  ></input>
                </div>
                <div className="w-1/2">
                  <label className="text-sm font-semibold block mb-2 dark:text-white">
                    Strategy
                  </label>
                  <div class="flex items-center dark:text-white font-semibold text-xs">
                    <label
                      className="relative flex items-center rounded-full cursor-pointer mr-1"
                      htmlFor="randomCLI"
                    >
                      <input
                        type="radio"
                        name="strategy"
                        id="randomCLI"
                        checked={strategy === "randomCLI"}
                        onChange={() => handleStrategyChange("randomCLI")}
                        className="peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-gray-900/10 dark:bg-white transition-all"
                      />
                      <span className="absolute text-white dark:text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                    Random CLI
                  </div>
                  <div class="flex items-center dark:text-white font-semibold text-xs">
                    <label
                      className="relative flex items-center rounded-full cursor-pointer mr-1 mt-1"
                      htmlFor="randomCLIWithGEO"
                    >
                      <input
                        type="radio"
                        name="strategy"
                        id="randomCLIWithGEO"
                        checked={strategy === "randomCLIWithGEO"}
                        onChange={() =>
                          handleStrategyChange("randomCLIWithGEO")
                        }
                        className="peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-gray-900/10 dark:bg-white transition-all"
                      />
                      <span className="absolute text-white dark:text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                    Random CLI with GEO
                  </div>
                </div>
              </div>
              <div className="">
                <label className="text-sm font-semibold block mb-2 dark:text-white">
                  Color
                </label>
                <div className="flex items-center">
                  <button
                    onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5  mr-1 dark:text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                      />
                    </svg>
                  </button>
                  <span
                    key={color}
                    style={{ backgroundColor: color }}
                    className="w-full flex rounded-full dark:text-white px-2 border"
                  >
                    {color}
                  </span>
                </div>
                <div>
                  {isColorPickerOpen && (
                    <SketchPicker
                      color={color}
                      onChangeComplete={handleChangeComplete}
                    />
                  )}
                </div>
              </div>
              {/* <div className="mt-4">
                <label className="text-sm font-semibold block mb-2 dark:text-white">
                  Pool
                  <div class="flex items-center dark:text-white font-normal text-xs mt-1">
                    <label className="relative flex items-center rounded-full cursor-pointer mr-1">
                      <input
                        type="checkbox"
                        checked={includeUsedNumberings}
                        onChange={handleIncludeUsedNumberingsChange}
                        className="peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-gray-900/10 dark:bg-white transition-all"
                      />
                      <span className="absolute text-white dark:text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                    Include numberings in use by other campaigns
                  </div>
                </label>
                <div className="rounded-full items-center w-full mr-4">
                  {/* <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={numberingOptions}
                  className="text-sm"
                  styles={customStyles}
                  onChange={handleNumberingChange}
                  value={numberingSelected}
                /> */}
              {/* {poolLoading ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <DualListDragDropMemoized
                      numberingOptions={numberingOptions}
                      setNumberingOptions={setNumberingOptions}
                      numberingSelected={numberingSelected}
                      setNumberingSelected={setNumberingSelected}
                    />
                  )}
                </div>
              </div> */}
            </div>
          </div>
          <div className="font-semibold text-sm mt-[6vh]">
            {response && response.status && response.message ? (
              <>
                {response.status === "error" ? (
                  <div className="text-red-500">ERROR: {response.message}</div>
                ) : (
                  <div className="text-green-600">{response.message}</div>
                )}
              </>
            ) : (
              ""
            )}
          </div>
          <div className="justify-between w-full items-center flex mt-2">
            <button
              onClick={() => setShowCampaigns(false)}
              className="rounded-full dark:bg-gray-300 font-semibold py-1 px-6 text-xl"
            >
              Close
            </button>
            <button
              onClick={handleSave}
              className={`rounded-full font-semibold dark:text-white py-1 px-6 text-xl ${
                isLoading ? "dark:bg-gray-500" : "dark:bg-gray-700"
              }`}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div
                    className="spinner-border animate-spin inline-block w-5 h-5 border-2 rounded-full"
                    role="status"
                  ></div>
                  <span className="visually-hidden ml-3">Loading...</span>
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignsAdd;
